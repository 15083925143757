@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --primary-color : rgb(13,17,40);
    --secondary-color: rgb(255,255,255);
    --tertiary-color:rgb(253,95,0);
}

@media (max-width:768px) {
    html {
        font-size: 12px;
    }
}

@media (max-width:576px) {
    html {
        font-size: 10px;
    }
}

body{
    font-family: 'Poppins', sans-serif;
}

/* ------------------NavBar--------------------- */
nav{
    width: 100vw;
    padding: .5em 2em;
    color: var(--secondary-color);
    background-color: var(--primary-color);

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
}

.navbar-inner-container{
    width: 100%;
    padding-top: .5em;
    height: 45px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (min-width:768px){
    .navbar-inner-container{
        width: 80%;
    }
}

.school-logo{
    display: inline-block;
    height: 100%;
    width: 30px;
}
.school-logo img{
    width: 100%;
}

.navlinks{
    height: 100%;
    display: inline-block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1.5em;

    list-style: none;
}
.navlinks li{
    cursor: pointer;
    position: relative;
}

.navlinks li a{
    color: white;
    text-decoration: none;
}

.navlinks li a:hover{
    color: rgb(12, 72, 202);
}

.navlinks .logout-navlink{
    margin-left: 2em;
    background-color: rgba(255,0,0,0.8);
    padding:.5em 2em;
}

.navlinks .logout-navlink:hover{
    background-color: transparent;
    padding: .35em 1.85em;
    border: 2px solid rgba(255,0,0,0.8);
}

.navlinks .logout-navlink:hover a {
    color: rgba(255, 0, 0, 0.8);
}

/* animation */
@keyframes openup{
    0%{
        height: 0%;
    }
    100%{
        height: 400%;
    }
}

.drop-down{
    display: none;
    position: absolute;
    left: -40px;
    top: 100%;
    overflow: hidden;
    background-color: var(--primary-color);
}

.navlinks li:hover .drop-down{
    display: block;
    animation-name: openup;
    animation-duration: 1s;
}

.drop-down ul{
    display: block;
    margin: 10px;
    padding: 10px;
    list-style: none;
    width: 160px;

}
.drop-down ul li a {
    color: var(--secondary-color);
    text-decoration: none;
}

.drop-down ul li:hover a {
    color: rgb(12, 72, 202);
}
.drop-down ul li:first-child{
    margin-bottom: 1em;
}

/* ------------------NavBar Ends--------------------- */
/* -----------------REGISTER PAGE--------------------- */
.register{
    width: 100vw;
    min-height: 100vh;
    color: var(--secondary-color);
    background: linear-gradient( rgba(0,0,0, 0.95), rgba(0,0,0, 0.95)), url("./images/reg-bg3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

        /* form container */
.container{
    width: 700px;
    padding: 2em 1em;
    box-shadow: 0 0 10px 0.01px gray;
    background-color: rgba(255,255,255, 0.05);

    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1.5em;
}

@media (max-width:768px){
    .container{
        width: 80%;
    }
}

@media (max-width: 576px){
    .container{
        width: 95%;
    }
}
        /* header text */
.register-header{
    font-size: 3rem;
    text-decoration: underline;
}

        /* form */
.reg-form{
    width: 100%;
    max-height: 550px ;
    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1em;
}

.form-inputs-container{
    width: 100%;
    margin-bottom: 2em;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2em;
}

.inputs-bank-1, .inputs-bank-2{
    width: 45%;
}

@media (max-width:576px){
    .reg-form{
        overflow-y: scroll;
    }

    .form-inputs-container{
        flex-direction: column;
    }

    .inputs-bank-1, .inputs-bank-2{
        width: 100%;
    }
}

.inputs-bank-1 input, .inputs-bank-2 input{
    display: block;
    width: 100%;
    height: 35px;
    color: var(--secondary-color);
    padding: 1em 1em 0.5em;
    background: transparent;
    border: 2px solid var(--tertiary-color);
    border: 0;
    border-bottom: 2px solid var(--tertiary-color);
    /* border-radius: .5em; */
    margin-bottom: 1.5em;

}

.reg-password{
    position: relative;
}

.reg-password p{
    position: absolute;
    right: 1em;
    margin-top: -3.5em;
    cursor: pointer;
}

.submit-btn{
    font-size: 1.5rem;
    color: var(--secondary-color);
    width: 300px;
    height: 60px;
    /* background: none; */
    background: rgba(253,95,0, 0.3);
    border: 1px solid var(--tertiary-color);
    transition: .7s;
}

.submit-btn:hover{
    background: var(--tertiary-color);
    border: 2px solid var(--tertiary-color);
}

        /* login option */
.reg-text{
    font-size: .8rem;
    word-spacing: .3em;
    letter-spacing: 0.08em;
}
.reg-text button{
    font-style: italic;
    font-size: 1rem;
    text-decoration: underline;
    color: var(--tertiary-color) !important;
    color: inherit;
    background: transparent;
    border: none;
    transition: .4s;
}

.reg-text button:hover{
    color: rgba(255,255,255, 01) !important;

}


/* -----------------Modal Start ----------------- */
.modal-container{
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    position: relative;
    z-index: 5;
}
.modal-wrapper{
    width: 90%;
    min-height: 100px;
    padding: 1em;
    color: rgba(255, 255, 255, 0.8);
    background:rgba(0,0,0,0.6) ;
    box-shadow: 0 0 10px .01px rgba(255,255,255, 0.2);
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    font-weight: bold;
}

@media(min-width:768px){
    .modal-wrapper{
        width: 50%;
        max-width: 500px;
    }
}

.modal-wrapper h1{
    
}

.modal-wrapper p{
    font-size: 1.2rem;
}

.modal-close-btn{
    font-weight: bold;
    background:rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 30px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 1em;
    top: 1em;

}

.goto-login{
    font-size: 1.2rem;
    width: 200px;
    height: 40px;
}

/* -----------------Modal End ----------------- */

.error-msg{
    display: block;
    font-style: italic;
    /* visibility: hidden; */
}

/* -------------login Start ----------------- */
.login{
    width: 100vw;
    height: 100vh;
    color: var(--secondary-color);
    background: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.9)), url("./images/login-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    display:flex;
    justify-content: center;
    align-items: center;
}


.login-wrapper{
    width: 95%;
    padding: 2em;
    background: linear-gradient(rgba(255,255,255,0.1), rgba(155,255,255,0.1));

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1em;
}

@media (min-width:768px){
    .login-wrapper{
        width: 50%;
        max-width: 700px;
    }
}

.school-logo-login{
    width: 100px;
}

.school-logo-login img{
    width: 100%;
}

.form-area-container{
    width: 100%;
}

.login-form{
    padding: 2em 0;
}

.login-form div{
    width: 100%;
    position: relative;
}

.login-form div input{
    width: 100%;
    height: 40px;
    color: var(--secondary-color);
    margin-top: .5em;
    background: transparent;
    border: none;
    border-bottom: 2px solid var(--tertiary-color);
}

.login-form p{
    position: absolute;
    color: gray;
    right: 20px;
    margin-top: -2em;
    cursor: pointer;
    z-index: 4;
   
}

.login-btn{
    width: 300px;
    height: 60px;
    margin: 1em;
}

@media (max-width:320px){
    .login-btn{
        width: 90%;
    }
}


.goto-register{
    text-align: center;
}

.goto-register button{
    font-style: italic;
    font-size: 1rem;
    text-decoration: underline;
    color: var(--tertiary-color) !important;
    color: inherit;
    background: transparent;
    border: none;
    transition: .4s;
}

.goto-register button:hover{
    color: rgba(255, 255, 255, 01) !important;
}
/* -------------login Ends ----------------- */

/* ---------------Home------------------ */
.home{
 margin-top: 60px;
}

.home-banner{
    width: 100vw;
    height: calc(100vh - 60px);
    padding: 0 2em;
    background: linear-gradient(to left, rgba(253,
        95,
        0,0.5),rgba(253,
                95,
                0, 0.5), rgba(255,
        255,
        255,
        0.5)), url("./images/home-banner2.webp");
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        justify-content: flex-start;
        align-items: center;
}

.banner-text{
    width: 70%;
    color: rgba(0,0,0, 0.8);
}

@media (min-width:768px){
    .home-banner{
        padding: 0 5em;
    }
    .banner-text{
        width: 40%;
    }
}
.banner-text button{
    width: 200px;
    height: 40px;
    border: none;
    color: var(--secondary-color);
    background: var(--tertiary-color);
    transition: .5s;
}

.banner-text button:hover{
    background: none;
    border: 2px solid var(--tertiary-color);
    color: rgba(0, 0, 0, 0.8);
}

.features-card{
    width: 100%;
}

.features-card .single-card{
    width: 100%;
    text-align: justify;
    padding: 3em 2em;
    color: var(--secondary-color);
    transition: .3s;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1em;
}
.features-card .single-card:hover{
    margin-top: -20px;
    border: 5px solid white;
}

@media (min-width:768px){
    .features-card{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .features-card .single-card{
        width: 25%;
    }
}

.features-card .odd{
    background-color: var(--primary-color);
}

.features-card .even {
    background-color: var(--tertiary-color);
}
/* ---------------Home Ends------------------ */

/* ----------------View Course---------------- */
.view-course, .register-course, .view-profile, .exams, .update-single-student{
    margin-top: 60px;
    border-top: 2px solid #000;
}

/* -----------------------View Profile---------------- */
.view-profile{
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
    text-align: center;
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-container{
    width: 100%;
    padding: 20px;
    margin: 20px;
    background-color: rgb(253, 95, 0, 0.3);
}

@media (min-width:768px){
    .profile-container{
        width: 600px;
    }
}

.profile-page-title{
    text-align: center;
    border-bottom:2px solid var(--primary-color) ;
    padding: 1em 0 0;
    margin-bottom: 2em;
}

.profile-photo-wrapper{
    width: 200px ;
    margin: 0 auto 20px;
    overflow: hidden;
}

.profile-photo-wrapper img{
    width: 100%;
}

.student-profile-wrapper{
    /* border: 2px solid red; */
}

.field-wrapper{
    margin-bottom: 20px;
}

.profile-label{
    color: rgba(0,0,0, 0.3);
}

.update-profile-btn{
    font-size: 1rem;
    width: 300px;
    height: 60px;
    border: none;
    background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
    transition: .6s ;
}

.update-profile-btn a{
    color: rgba(255, 255, 255, 1);
}

.update-profile-btn:hover{
    background-image: linear-gradient(to right, rgba(106, 17, 203, 0.8), rgba(37, 117, 252, 0.8) );
}

@media (max-width:400px) {
    .update-profile-btn{
        width:100%;
    }
}


/* update single student */
.update-single-student{
    min-height: calc(100vh - 60px);
    position: relative;
}

.update-single-student-form-wrapper{
    width: 100%;
    /* border:2px solid rgba(0,0,0, 0.5); */
    padding: 40px 20px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media (min-width:768px){
    .update-single-student-form-wrapper{
        width: 600px;
    }
}

.update-form>div{
    margin-bottom: 1em;
}

.update-form>div>input{
    padding: 0 1em;
    width: 100%;
    height: 40px;
}

.update-student_password-field:hover input{
    border: none;
}

.update-student_password-field:hover{
    border: 2px solid rgba(255, 0, 0, 0.8);
    border-radius: 5px ;
}

.update-student_password-field span{
    float: right;
    top: .8em;
    right: 1.5em;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.update-form .update-btn{
    margin-top: 30px;
    width: 100%;
    height: 60px;
    background: rgba(0,0,0, 0.2);
    border: none;
    cursor: pointer;
}
/* -----End----- */

/* Register Course */
.register-content-container{
    width: 97%;
    padding: 20px;
    margin: 2em auto 0;
    /* border:2px solid red ; */
}

@media (min-width:768px){
    .register-content-container{
        width: 750px;
    }
}

.reg-headers{
    text-align: center;
    margin-bottom: 1em;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: .5em;
    /* border: 2px solid rebeccapurple; */
}

.reg-form-container form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1em;
}

.course-row-number{
    font-size: 1rem;
    margin-left: -10px;
    margin-right: 10px;
}

.input-field-row-container{
    width: 100%;
    margin-bottom: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: .2em;
}

.input-field-row-container input{
    text-align: center;
    width: calc(100% / 3);
    height: 35px;
    background: transparent;
    transition: .3s;
}

.delete-course-row-btn{
    font-size: 1.2rem;
    margin-left: .5em;


    display: none;
    width: 35px;
    height: 35px;
    border: 2px solid gray;
    border-radius: 50px;
    cursor: pointer;
}

.input-field-row-container:hover .delete-course-row-btn{
    display: inline-block;

    display: flex;
    justify-content: center;
    align-items: center;
}

.reg-form-container .add-new-course-btn{
    height: 40px;
}

.submit-course-btn{
    height: 40px;
    width: 100%;
    margin: 2em auto 0;
}

@media (min-width: 576px){
    .submit-course-btn{
        width: 50%;
    }
}

.view-course .input-field-row-container input:hover {
    background-color: #555;
    color: rgba(255, 255, 255, 0.9);
}

/* no-match-routes */
.no-match-routes{
    height: 100vh;
    margin-top: 60px;
    position: relative;
}

.no-match-routes .content-container{
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
}

.no-match-routes .content-container img{
    width: 90%;
}

@media (min-width:270px) {
    .no-match-routes .content-container img {
        width: 30%;
        min-width: 250px;
    }
}

.no-route-image {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .no-route-image {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.no-match-routes .go-to-home-btn{
    font-size: 1.3rem;
    width: 300px;
    height: 60px;
    border: none;
    background: #4A4A4C;
}
@media (max-width:350px) {
    .no-match-routes .content-container .go-to-home-btn {
        width: 80%;
    }
}

.no-match-routes .go-to-home-btn a{
    color: rgb(237, 195, 48);
}


